<template>
  <div>
    <!-- HANDELBAY INNOVATIVE -->
    <section id="buyers" class="providers">
			<div class="container">
				<div class="row">
					<div class="col-md-6 col-sm-7 col-md-offset-1 text-left">
						<div class="title" itemscope itemtype="https://handelbay.com/">
							<h2 class="title-1" itemprop="HandelBay seguridad">¿Querés llegar a más clientes</h2>
							<h3 class="title-2" itemprop="HandelBay transparancia">y alcanzar mayores ventas?</h3>
							<span class="handelbay-active"></span>
						</div>
						<div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
							<p itemprop="HandelBay es la solución">
								Markley HandelBay es una plataforma de ventas empresariales, que te permite llegar a nuevos clientes y participar en más cotizaciones.
							</p>                    
						</div>
						<br>
						<div class="title-footer">
							<a :href="global.sign_up" class="btn btn-handelbay-success btn-handelbay-black" style="margin-top:15px !important;">
								REGISTRATE AHORA
							</a>
						</div>
					</div>
					<div class="col-md-5 col-sm-5">
							<div class="computers">
									<img src="../assets/images/landing/handelbay/handelbay_quieres_llegar.png"  alt="Es hora de usar HandelBay en tu día a día">
							</div>
					</div>
				</div>
				<!-- .row -->
			</div>
			<!-- .container -->
    </section>
    <!-- END HANDELBAY INNOVATIVE -->

    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva" class="handelbay-background-curva-buyers">
    </section>
    <!-- .handelbay-background-curva -->

    <!-- Ahorros en las compras -->
    <section id="savings_in_purchases" class="savings_in_purchases"> 
			<div class="container">
				<div class="row">
					<div class="col-md-4 col-sm-5 col-md-offset-1 col-xs-8 col-xs-offset-2 col-sm-offset-0">
						<div class="img_man">
							<img src="../assets/images/landing/handelbay/handelbay_business_man.png" alt="Es hora de usar HandelBay en tu día a día">
						</div>
					</div>
					<div class="col-md-6 col-sm-7 text-right col-xs-12">                    
						<div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
							<h2 class="title"><b>Más clientes y menos esfuerzo</b></h2>
							<p class="text" itemprop="HandelBay es la solución">
								¿Imaginás que los compradores te busquen para participar en sus procesos de compra? ¿Demorás semanas para llegar a un cliente? ¿Se vuelve difícil dar a conocer tu empresa, su portafolio y la reputación?.
							</p>                        
							<p class="text">
								En Markley HandelBay vinculamos compradores potenciales para que te des a conocer y recibas invitaciones a participar en sus procesos. Así facilitamos hasta en un 80% tu labor comercial.
							</p>                        
							<h3 class="title space-title"><b>Seguridad en las negociaciones</b></h3>         
							<p class="text">
								En Markley HandelBay podés hacer negocios con seguridad y confianza. Para ello, revisamos la documentación de todas las empresas (compradores y proveedores). Validamos ante diferentes entidades estatales y privadas, la documentación básica de las empresas.
							</p>
						</div>                    
					</div>
				</div>
			</div>
    </section>
    <!-- End ahorros en las compras -->

    <!-- Red de expansión -->
    <section id="expansion_network" class="provider_network">
			<div class="container">
				<div class="row">
					<div class="col-md-4 col-sm-4 col-md-offset-1 text-left">
						<h2 class="title"><b>Portafolio completo en línea 24/7</b></h2>
						<p class="text" itemprop="HandelBay es la solución">
							Markley HandelBay tenés tu portafolio en línea 24/7 ante la red creciente de compradores. Las empresas podrán entrar a tu perfil y ver los productos y servicios que ofrecés con apoyos multimedia. ¡HandelBay es lo que estabas esperando para hacer crecer tu negocio!
						</p>                    
					</div>
					<div class="col-md-2 col-sm-4 col-xs-6 col-xs-offset-3 col-sm-offset-0">
						<div class="brand">
							<img src="../assets/images/landing/handelbay/handelbay_img_center.png" alt="Es hora de usar HandelBay en tu día a día">
						</div>
					</div>
					<div class="col-md-4 col-sm-4 text-right col-xs-12">                    
						<div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
							<h3 class="title"><b>Red social y calificaciones</b></h3>
							<p class="text" itemprop="HandelBay red empresarial">
								Markley HandelBay es una red social empresarial. Podrás conocer las otras empresas, contarle a otras empresas en que sos experto, tu trayectoria, tu portafolio, documentación y mucho más.
							</p>
							<p class="text">
								En Markley HandelBay recibís calificaciones de tus clientes por cada negocio cerrado. Además te podrán recomendar, así generás confianza para tus nuevos clientes.
							</p>
						</div>
					</div>
				</div>
			</div>
    </section>
    <!-- End Red de expansión -->

    <!-- Compara cotizaciones y proveedores -->
    <section id="compare_quotes">
			<div class="container">
				<div class="row">                    
					<div class="col-md-6 col-sm-7 col-md-offset-1">                    
						<div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
							<h2 class="title"><b>Gestioná tus clientes</b></h2>
							<p class="text" itemprop="HandelBay es la solución">
								Asigná clientes a cada una de las persona de tu grupo comercial. Así, podrás hacer un mejor seguimiento e incluso las ventas de cada uno.
								Podrás gestionar tus clientes y lo mejor es que tendrás su información actualizada al instante.
							</p>
							<p class="text space-title">
								En Markley HandelBay recibí más invitaciones, nosotros te notificamos antes que los procesos cierren y no te quedes por fuera. Markley HandelBay reúne todo lo que siempre soñaste para tu área comercial.
							</p>
							<h3 class="title space-title"><b>Invitaciones acertadas</b></h3>                     
							<p class="text">
								¿Qué pasa cuando en tu portfolio vendés papel de embalar y pero tu cliente busca resmas de papel para impresoras? ¡Quizás no te encuentre facilmente!<br>
							</p>                                                   
							<p class="text space-title">
								¿Has pensado además en traducir tu portafolio para llegar a nuevos mercados? Con Markley HandelBay podés hacer esto y mucho más.                              
							</p>                          
							<p class="text space-title">Cargá tu portafolio de productos y servicio, solo una vez, a través de la codificación de UNSPSC®. Tus nuevos clientes te encontrarán de manera acertada y rápida sin importar el idioma o el país en que se encuentran.<br>
								¡Esto es el futuro de las ventas! Hacer negocios sin fronteras comerciales.<br>
							</p>                                                               
						</div>                    
					</div>
					<div class="col-md-4 col-sm-5 col-xs-6 col-xs-offset-3 col-sm-offset-0">
						<div class="brand">
							<img src="../assets/images/landing/handelbay/handelbay_money_loan_about_us.png" alt="Es hora de usar HandelBay en tu día a día">
						</div>
					</div>
				</div>
			</div>
    </section>
    <!-- End Compara cotizaciones y proveedores -->

    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva-bottom">        
    </section>
    <!-- .handelbay-background-curva -->

    <!-- Listo para innovar -->
    <section id="ready_to_innovate">
			<div class="container">
				<div class="title-footer text-center">
					<h2 class="title" style="margin-top:27px;"><b><em>¿Estás listo para innovar en tu área comercial?</em></b></h2>
					<router-link to="/tarifas" class="btn btn-handelbay-info" @click.native="scrollUp">
						VER PLANES DE PROVEEDOR
					</router-link>
				</div>
			</div>
    </section>
    <!-- End Listo para innovar -->

    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva">
    </section>
    <!-- .handelbay-background-curva -->

    <!-- Optimiza tus compras -->
    <section id="optimize_your_purchases" class="optimize_your_purchases">
			<div class="container">
				<div class="row">
					<div class="col-md-10 col-sm-12 col-md-offset-1 text-left">
						<div class="title" itemscope itemtype="https://handelbay.com/">
							<h2 class="title-1" itemprop="HandelBay seguridad">Optimizá</h2>
							<h3 class="title-2" itemprop="HandelBay transparancia">tus ventas</h3>
						</div>
						<div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
							<p itemprop="HandelBay es la solución">
								En cuatro grandes grupos podrás comprender todas las características que ofrece HandelBay<br>
								para las áreas comerciales sin importar el tamaño de la empresa.
							</p>                    
						</div>   
						<div class="img_buyers">
							<img src="../assets/images/landing/handelbay/handelbay_optimiza_ventas.png" alt="Optimiza tus compras">
						</div>
					</div>                
				</div>
				<!-- .row -->
				<div class="title_content text-center">
					<h2 class="title">                        
						Más compradores, más oportunidades
						<i class="fa fa-caret-right"></i>
					</h2>
				</div>
				<div class="row">
						<div class="col-md-10 col-sm-12 col-md-offset-1 text-left">
							<div class="title" itemscope itemtype="https://handelbay.com/">
								<h2 class="title-1" itemprop="HandelBay seguridad">Funcionalidades para</h2>
								<h3 class="title-2" itemprop="HandelBay transparancia">proveedores</h3>                        
							</div>
								<Functionalities />
								<br><br>
								<div class="title-footer text-center">
									<a :href="global.sign_up" class="btn btn-handelbay-success btn-handelbay-black">
										REGISTRATE GRATIS
									</a>
								</div>
						</div>                
				</div>
				<!-- .row -->
			</div>
			<!-- .container -->
    </section>
    <!-- End Optimiza tus compras -->

    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva-bottom">        
    </section>
    <!-- .handelbay-background-curva -->

    <PorqueHandelbay />
    
    <ModalTuDemo />
  </div>
</template>
<script>
import global from '@/components/Global.vue'
import Functionalities from "@/components/FunctionalitiesProvider.vue"
import PorqueHandelbay from "@/components/PorqueHandelbay.vue"
import ModalTuDemo from "@/components/ModalTuDemo.vue"
export default{
	components:{
		Functionalities,
		PorqueHandelbay,
		ModalTuDemo
	},
	data(){
		return{
			global: global
		}
	},
	methods:{
		scrollUp: () => {
			window.scroll({top:0})
		}
	}
}
</script>
